import { swalAlertPopProps } from "../../utils/commonUtils";
import { customerNameByMobile } from "./counterSellBillingApi";

export const addBill = async ({
  payloadAddBill,
  addBillCounterSell,
  Swal,
  dispatch,
  setBillNo,
  navigate,
  routePath,
}) => {
  console.log("fucntion api", payloadAddBill);
  await addBillCounterSell(payloadAddBill)
    .then((res) => {
      if (res?.data?.succeeded) {
        Swal.fire({
          text: "Bill Saved Successfully",
          icon: "success",
          timer: 2000, // Specify the time in milliseconds (e.g., 2000ms = 2 seconds)
          showConfirmButton: false,
        });
        dispatch(setBillNo(res?.data?.data?.billNumber));
        // dispatchEvent()
      } else {
        Swal.fire({
          text: res?.data?.message,
          icon: "error",
          ...swalAlertPopProps,
        });
      }

      navigate(routePath.COUNTERSELL);
    })
    .catch((err) => {});
};
export const handleDataChange = (
  snapshot,
  setBill,
  bill,
  setCurrentDataArr
) => {
  const tableOrderData = [];
  snapshot.forEach((childSnapshot) => {
    const tableName = childSnapshot.key;
    const tableData = childSnapshot.val();

    tableOrderData.push({ tableName, ...tableData });
  });
  const arr1 = Object.entries(tableOrderData);
  console.log("billp  arrrrrrrr= :",arr1);

  let sum = 0;
  let priceWithoutGst = 0;
  let sumOfGst = 0;
  arr1.forEach((item) => {
    sum += Number(item[1].price * item[1].suppliedQuantity);
    priceWithoutGst += Number(item[1].price * item[1].suppliedQuantity);
    sumOfGst +=Number(item[1].price * item[1].suppliedQuantity)* (Number(item[1].gst)/100);
  });
  console.log("billp sum :",sum);
  console.log("billp priceWithoutGst= :",Number(priceWithoutGst+sumOfGst));
  console.log("billp sumOfGst :",sumOfGst);
  setBill((prev)=>{
    let prevData={...prev}
    prevData.amount=priceWithoutGst
    prevData.paybill=(priceWithoutGst)
    prevData.sumOfGst=sumOfGst
    prevData.priceWithoutGst=priceWithoutGst
    return prevData
  })
  // { ...bill, amount: sum, paybill: sum, sumOfGst:sumOfGst, priceWithoutGst:priceWithoutGst });
  setCurrentDataArr(arr1);
};

export const getItems = async (
  param,
  ItemGETApi,
  CollectionTableItems,
  setCollectionTableItems,
  setIndexTableItem,
  setNumberOfRecords
) => {
  await ItemGETApi(param)
    .then(async (res2) => {
      let itemId = 0;
      let arry = res2.data.data;

      let arr = [...CollectionTableItems];
      arr.push({
        itemId: itemId,
        tableItems: arry,
      });
      console.log("get items counter ", res2?.data?.totalRecords);
      const index2 = arr.findIndex((obj) => obj.itemId === 0);
      setIndexTableItem(index2);
      setCollectionTableItems(arr);
      setNumberOfRecords(res2?.data?.totalRecords);
    })
    .catch(() => {});
};

export const getcustomerNameByMobile = async (param) => {
  let res = null;
  // await customerNameByMobile(param)
  //   .then((res) => {
  //     console.log("customer name ", res?.data?.data);
  //     res = res?.data?.data;
  //   })
  //   .catch((err) => {});
  // return res;
  try {
    const response = await customerNameByMobile(param);
    console.log("calb customer name ", response?.data?.data);
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching customer name", error);
    return null;
  }

  // try {
  //   const response = await customerNameByMobile(param);
  //   console.log("customer name ", response?.data?.data);
  //   return response?.data?.data;
  // } catch (error) {
  //   console.error("Error fetching customer name", error);
  //   return null;
  // }
};
